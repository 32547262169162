import Vue from 'vue'
import VueRouter from 'vue-router'
import jwt_decode from "jwt-decode";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: () => import('../views/Users.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/banks',
    name: 'banks',
    component: () => import('../views/Banks.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/properties',
    name: 'properties',
    component: () => import('../views/Properties.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/insurers',
    name: 'insurers',
    component: () => import('../views/Insurers.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/collections',
    name: 'collections',
    component: () => import('../views/Collections.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import('../views/Payments.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reportPays',
    name: 'reportPays',
    component: () => import('../views/ReportPays.vue'),
    meta: { requiresAuth: true }
  },
  // {
  //   path: '/collectivePay',
  //   name: 'collectivePay',
  //   component: () => import('../views/CollectivePay.vue'),
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/branches',
    name: 'branches',
    component: () => import('../views/Branches.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import('../views/Customers.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/companies',
    name: 'companies',
    component: () => import('../views/Companies.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/policyholders',
    name: 'policyholders',
    component: () => import('../views/Policyholders.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/collectives',
    name: 'collectives',
    component: () => import('../views/Collectives.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/increments',
    name: 'increments',
    component: () => import('../views/Increments.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/policies',
    name: 'policies',
    component: () => import('../views/Policies.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reportPolicies',
    name: 'reportPolicies',
    component: () => import('../views/ReportPolicies.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reportPolicy',
    name: 'reportPolicy',
    component: () => import('../views/ReportPolicy.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/ingreso',
    name: 'ingreso',
    component: () => import('../views/Ingreso.vue'),
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: () => import('../views/ResetPassword.vue'),
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async(to, from, next) => {
  const ruta_protegida = to.matched.some(record => record.meta.requiresAuth);
  const user = localStorage.usuario;
  console.log(user);
  if (to.name != 'ingreso' && localStorage.permisos == undefined) {
    next({path: '/ingreso'})
  }

  if (ruta_protegida === true && (user === null || user == undefined)) {
    next({path: '/ingreso'})
  }
  else {
    try {
      const permisos = JSON.parse(localStorage.permisos);
      const screen = to.name;
      const master = jwt_decode(localStorage.token).master;

      console.log(permisos[screen], master);
      
      if(screen == 'usuarios' && master == '0') {
        next({path: '/ingreso'})
      }

      if(permisos[screen] == '0' && master == '0') {
        next({path: '/ingreso'})
      }
    }
    catch { }
    next();
  }
})

export default router
