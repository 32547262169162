<template>
  <nav>
    <v-app-bar app color="#69727a" dark >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      {{ username }}
      <!-- <v-spacer></v-spacer>
        <v-btn text @click="download">
          <v-icon>fas fa-cloud-arrow-down</v-icon>
        </v-btn> -->
      <v-spacer></v-spacer>
      <v-btn text @click="cerrarSesion">
        <span class="mr-2">Cerrar Sesión</span>
        <v-icon>fas fa-power-off</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer color="#69727a" v-model="drawer" 
    dark
    app 
    :temporary="temporary"
    :permanent="permanent"
    :src="getImage"
    >
<!--  -->
           <v-list-item two-line>
            <v-list-item-avatar>
              <img src="@/assets/logo.png">
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Correduria de Seguros</v-list-item-title>
              <v-list-item-subtitle>COSENM</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <template v-for="item in items">
          <v-row
            v-if="item.heading"
            :key="item.heading"
            align="center"
          >
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col
              cols="6"
              class="text-center"
            >
              <a
                href="#!"
                class="body-2 black--text"
              >EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.title"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon=""
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              :to="child.to"
              link
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :key="item.title"
            :to="item.to"
            link
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>


      <!-- </v-list> -->
    </v-navigation-drawer>
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="320"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Comprimiendo archivos, espere por favor
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </nav>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
// import { saveAs } from 'file-saver';
// import * as moment from 'moment';
import jwt_decode from "jwt-decode";
import bg from '../assets/fondomenu1.jpg';
export default {
  name: "navbar",
  methods: {
    ...mapActions(["cerrarSesion"]),
    // async download() {
    //   this.dialog = true;
    //   // setTimeout(() => (this.dialog = false), 4000)
    //   await axios.get(this.db + 'download/zip',
    //   {
    //     responseType: 'arraybuffer',
    //     headers: {
    //         "Content-type": "application/x-www-form-urlencoded",
    //         "Content-Encoding": "gzip",
    //         'Authorization': 'BEARER ' + localStorage.token
    //     }
    //   })
    //   .then(response => {
    //       console.log(response );
    //       this.dialog = false
    //       let blob = new Blob([response.data], { type: 'application/zip' } )
    //       let name = moment().format("DD-MM-YYYY hh:mm:ss") + ".zip";
    //       saveAs(blob, name);
    //   })
    //   .catch(error => {
    //       this.dialog = false
    //       console.log( error );
    //   });
    // },
    download() {
      axios.get(this.db + 'download/zip', {
        responseType: 'blob', // Indica que la respuesta será un objeto Blob
        headers: {
          Authorization: 'BEARER ' + localStorage.token // Añade cualquier encabezado adicional necesario, como la autenticación
        }
      })
      .then(response => {
        // Crea un objeto Blob a partir de la respuesta
        const blob = new Blob([response.data], { type: 'application/zip' });

        // Crea una URL para el Blob
        const url = window.URL.createObjectURL(blob);

        // Crea un enlace <a> para iniciar la descarga
        const link = document.createElement('a');
        link.href = url;
        link.download = 'archivo.zip'; // Establece el nombre de archivo deseado

        // Simula un clic en el enlace para iniciar la descarga
        link.click();

        // Limpia la URL del objeto Blob
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error(error);
        // Maneja cualquier error que ocurra durante la descarga
      });
    }
  },
  computed: {
    ...mapState(["db","userType"]),
    items() {
      console.log(this.permisos);
        if (this.master == 1) {
          return this.list;
        }
        else {
          this.list.forEach(element => {
            if (this.permisos[element.to.name] == 1) {
              this.muestreo.push(element);
            }
          });
          return this.muestreo;
        }
    },
    temporary() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return false
        case 'lg': return false
        case 'xl': return false
      }
    },
    permanent() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return false
        case 'sm': return false
        case 'md': return true
        case 'lg': return true
        case 'xl': return true
      }
    },
    getImage() {
     return bg;
    }
  },
  data() {
    return {
      username: '',
      master: jwt_decode(localStorage.token).master || '',
      permisos: '',
      drawer: false,
      // mini: false,
      dialog: false,
      group: null,
      type: null,
      muestreo: [],
      list: [
        // { title: "Home", icon: "fas fa-chart-bar", to:{name: 'home'} },
        { title: "Usuarios", icon: "fas fa-user-secret", to:{name: 'usuarios'} },
        { title: "Bancos", icon: "fas fa-money-check-alt", to:{name: 'banks'} },
        { title: "Bienes", icon: "fas fa-city", to:{name: 'properties'} },
        { title: "Aseguradoras", icon: "fas fa-university", to:{name: 'insurers'} },
        { title: "Ramos", icon: "fas fa-clinic-medical", to:{name: 'branches'} },
        { title: "Clientes", icon: "fas fa-users", to:{name: 'customers'} },
        { title: "Persona Juridica", icon: "fas fa-person", to:{name: 'companies'} },
        { title: "Asegurados", icon: "fas fa-person-circle-plus", to:{name: 'policyholders'} },
        { title: "Pólizas", icon: "fas fa-file-invoice-dollar", to:{name: 'policies'} },
        { title: "Reporte de Pólizas", icon: "fas fa-file-medical", to:{name: 'reportPolicies'} },
        { title: "Cobranza", icon: "fab fa-cc-apple-pay", to:{name: 'collections'} },
        { title: "Reporte de Pagos", icon: "fas fa-money-bill-alt", to:{name: 'reportPays'} },
        // { title: "Component pay", icon: "fas fa-cloud-arrow-down", to:{name: 'collectivePay'} },
      ],
      right: true
    };
  },

  watch: {
      group () {
        this.drawer = false
      },
  },

  created() {
    this.type = localStorage.userType;
    this.username = localStorage.username || '';
    this.permisos = JSON.parse(localStorage.permisos) || '';
  }
};
</script>

<style>
 /* v-list-group__header v-list-item v-list-item--active v-list-item--link theme--dark */

/* v-list-item--active v-list-item v-list-item--link theme--dark {
  color: red;
} */
.v-application a {
    color: #ffffff !important;
}

.v-list-group.v-list-group--active.primary--text {
   color: #8D4925 !important;
    caret-color: #8D4925 !important;
}

.v-list-group__items {
  padding-left: 16px
}
</style>