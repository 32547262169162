import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);
import es from 'vuetify/es5/locale/es';

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  lang: {
    locales: {
      es
    },
    current: 'es',
  },
});