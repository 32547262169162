import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import sha256 from 'crypto-js/sha256';
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    usuario: '',
    id_user: '',
    userType: '',
    // db: 'http://localhost/infocafe/API-SEGUROS/api/',
    db: 'https://cosenm.com/api-seguros/api/',
    headers_db: {
      'Content-Type': 'application/json',
      'Authorization': 'BEARER ' + localStorage.token
    },
    error: '',
    customers: [],
    usuarios: [],
    carga: false,
    snackbars: [],
  },
  mutations: {
    setSnackbar(state, snackbar) {
      snackbar.showing = true;
      snackbar.color = snackbar.color || 'success';
      state.snackbars = state.snackbars.concat(snackbar);
    },
    setHeader(state, token) {
      state.headers_db.Authorization = 'BEARER ' + token;
    },
  },
  actions: {
    ingresoUsuario({
      commit
    }, payload) {
      // alert(sha256(payload.pass).toString());
      axios.post(this.state.db + 'login', {
        user: payload.user,
        pass: sha256(payload.pass).toString()
      })
      .then(async(res) => {
        if (res.data.status == 0) {
          // localStorage.setItem("usuario", payload.user);
          router.push({
            name: 'resetpassword', params: { user: payload.user }
          })
        } 
        else {
          let current_user = res.data.data;
          this.state.usuario = current_user.user;
          this.state.id_user = current_user.id;
          commit('setHeader', current_user.token);
          localStorage.setItem("token", current_user.token);
          localStorage.setItem("usuario", current_user.user);
          // localStorage.setItem("master", current_user.master);
          localStorage.setItem("username", current_user.name);
          localStorage.setItem("id_user", current_user.id);

          await axios.get(this.state.db + 'permits/' + current_user.id,
          {
            headers: this.state.headers_db
          }).then(response => {
            localStorage.setItem("permisos", JSON.stringify(response.data.data));
          })
          .catch((error) => {
            commit('setSnackbar', {
              color: "error",
              text: error
            });
          });

          router.push({
            name: 'Home'
          })
        }
      })
      .catch(err => {
        commit('setSnackbar', {
          color: "error",
          text: "Hay un error en sus credenciales"
        });
        console.log(err.mensaje);
      })
      
    },

    updatePassword({
      commit
    }, payload) {
      axios.post(this.state.db + 'update_password', {
        user: payload.user,
        pass: sha256(payload.password).toString()
      })
      .then(() => {
        router.push({
            name: 'ingreso'
          })
      })
      .catch(err => {
        commit('setSnackbar', {
          color: "error",
          text: "Hay un error en sus credenciales"
        });
        console.log(err.mensaje);
      })
      
    },

    cerrarSesion({
      commit
    }) {
      this.state.usuario = '';
      localStorage.removeItem("usuario");
      localStorage.removeItem("token");
      router.push({
        name: 'ingreso'
      })
    },

    validateSession({ dispatch, commit }, error) {
      console.log(error.response);
      if (error.response) {
          let token = error.response.data.token;
          if (error.response.status == 400) {
            commit('setSnackbar', {
              text: error.response.data.message,
              color:'error'
            });
            return false;
          }
          
          if (token != null) {
            localStorage.token = token;
            commit('setHeader', token);
            return true;
          }
          
          if (error.response.status == 401) {
            commit('setSnackbar', {
              text: 'La sesión ha caducado',
              color:'error'
            });
            dispatch('cerrarSesion');
            return false;
          }
      }
    },

    setSnackbar({
      commit
    }, snackbar) {
      commit('setSnackbar', snackbar);
    },

    // setHeader({
    //   commit
    // }, headers) {
    //   commit('setHeader', headers.token);
    // }
  },
  modules: {
  }
})
